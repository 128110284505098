import {mapMutations, mapActions, mapGetters} from "vuex";


//sections


export default {
    name: "projects",
    components: {},

    data() {
        return {
            crumbs: [
                {src: 'home', title: this.$t('home.title'), slug: '/'},
                {src: 'projects', title: this.$t('projects.title'), slug: 'projects'}
            ],
            isPlayVideo: false,
        }

    },
    watch: {
        '$route.query'() {
            let slug = this.$route.params.slug
            this.getContent(slug).then(() => {
                document.title = `${this.content.title}`
            })
        }

    },
    mounted() {
        let slug = this.$route.params.slug
        this.getContent(slug).then(() => {
            document.title = `${this.content.title}`
        })
    },
    created() {
        this.setRequestFlag(true);
    },
    computed: {
        ...mapGetters({
            content: 'projects/projectData',
            globalRequestsFlag: 'system/globalRequestsFlag'
        }),
        dynamicWidth() {
            let benefits = this.content.projectBenefits.data.length
            if (window.innerWidth >= 800) {
                return (100 / benefits) + '%'
            }
            if (window.innerWidth >= 680) {
                return 50 + '%'
            } else {
                return 30 + '%'
            }

        }
    },
    methods: {
        ...mapActions({
            getContent: 'projects/GET_ONE_PROJECT'
        }),
        ...mapMutations({
            setRequestFlag: 'system/SET_REQUESTS_FLAG',
        }),
        playVideo() {
            this.isPlayVideo = !this.isPlayVideo
        }
    }
}
